<template>
  <div class="page">

    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>İleti Yönetim Sistemi Nedir?</h2>

        <div
          class="videoplay"
          @click="
            SET_MODAL('ModalVideoGenel');
            SET_NOTIFY_REQUESTER_TYPE(0);
          "
        >
          <div>
            <img
              class="playthumb"
              src="@/assets/img/video_thumb_02.png"
              alt
            />
            <img
              class="playbtn"
              src="@/assets/img/video_play.svg"
              alt
            />
          </div>
        </div>

        <div class="staticbox">
          <div class="p70">
            <h3>İleti Yönetim Sistemi tanıtım filmimizi izleyin</h3>
            <p>
              İleti Yönetim Sistemi hakkında bilgi edinmek için tanıtım
              filmimizi izleyin.
            </p>
          </div>
        </div>

        <social />

        <div class="staticbox">
          <div class="nedir alignleft">
            <img
              src="@/assets/img/hero/iys_nedir.svg"
              alt=""
              class="floatright"
            />

            <h3>İleti Yönetim Sistemi Nedir?</h3>

            <p>İleti Yönetim Sistemi (İYS), tüm hizmet sağlayıcıların güncel ticari elektronik ileti onaylarını kaydettikleri ulusal veri tabanı sistemidir. Alıcılar (vatandaşlar) onay verebildikleri gibi, mevcut onayları için ret haklarını da İYS üzerinde kullanabilirler.</p>

            <p>İYS’nin kuruluşu ve işleyişi hakkında bilgiye <router-link
                to="/iys/hakkimizda"
                class="blue"
              ><strong>Yönetim Beyanı</strong></router-link>'ndan ve <router-link
                to="/iys/sss"
                class="blue"
              ><strong>Sıkça Sorulan Sorular</strong></router-link> bölümünden ulaşabilirsiniz.</p>

            <h3>Mevzuat</h3>

            <p>
              6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun,
              <router-link
                to="/iys/yonetmelik-degisiklik"
                class="blue"
              ><strong>Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik</strong></router-link>
              kapsamında, hizmet sağlayıcılar tarafından alıcılara gönderilecek ticari iletilerin, yönetmelik ile belirlenen istisnalar dışında alıcının onayı olmadan gönderilemeyeceği, izin dâhilinde gönderilen iletilerde ise alıcılara ret imkânı sağlanması gerektiği belirtilmiştir.
            </p>
            <!-- yeni yönetmelik yayınlanacağı için bu kısma yeni tarih ve yönetmelik ismini yazmalıyız (yazıldı, yine değiştirebiliriz) -->

            <p>
              <router-link
                to="/iys/kanun"
                class="blue"
              ><strong>Kanun</strong></router-link>'da ve
              <router-link
                to="/iys/yonetmelik-degisiklik"
                class="blue"
              ><strong>Yönetmelik</strong></router-link>'te tanımlandığı üzere ticari iletiler için alınacak iletişim onaylarında, onay fiziksel ortamda alınmış ise alıcının imzası gereklidir ancak pratikte bu belgelerin saklanması, arşivlenmesi, belgelerin güvenliğinin ve gizliliğinin sağlanması, gerektiğinde arşivden çıkarılması meşakkatli bir süreçtir. Elektronik ortamda alınması durumunda ise onayın alındığına dair bilgilendirmenin alıcının elektronik iletişim adresine aynı gün içinde iletilmesi gerektiği belirtilmiştir. Elektronik ortamda alınan onaylarda da benzer şekilde onayın izin sahibi kişiden alındığının elektronik kayıtlarının tutulması ve güvenli bir şekilde saklanması gerekmektedir.
            </p>
            <p>İYS ile hizmet sağlayıcıların, alıcılara göndereceği ticari elektronik iletiler öncesinde alması gereken izinler güvenli bir ortamda saklanacak, alıcılar onay ve ret bildirimlerini yapabilecek, bunun yanında şikâyet hakkını kullanabilecektir. Kamu denetleme görevini hızlı ve eksiksiz bir şekilde yerine getirebilecektir. </p>

            <!-- <h3>Mevcut durum</h3>
            <p>
              İzin alınan kişiler, kendilerinden ticari elektronik ileti izni
              alındıktan sonra hangi firmalara izin verdiklerini takip
              edememekte, ticari elektronik ileti izinlerini kaldırmak için
              kendilerine ticari elektronik iletiyle bildirilen ret imkânını her
              Hizmet Sağlayıcı için ayrı ayrı kullanmak zorunda kalmaktadırlar.
              Ticari elektronik ileti izini bulunmayan bir kişiye ileti
              gönderilmesi üzerine bu kişinin şikâyette bulunması halinde ise
              ilgili Hizmet Sağlayıcı'dan kişinin izin alınıp alınmadığı veya bu
              kişinin ret hakkını kullanıp kullanmadığı ile ilgili bilgi
              alınması ve bilgiler değerlendirilerek idari yaptırım kararlarının
              verilmesi gerekmektedir.
            </p> -->

            <!-- <h3>Amaç</h3>
            <p>
              Uygulamada yaşanan bu sorunların önüne geçilmesi amacıyla
              <router-link to="/iys/kanun" class="blue"
                ><strong>Kanun</strong></router-link
              >
              ve
              <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                ><strong>Yönetmelik</strong></router-link
              >'te gerekli değişiklikler yapılmıştır. Bu doğrultuda Ticaret
              Bakanlığı tarafından ulusal bir ticari elektronik ileti yönetim
              sisteminin kurulmasına karar verilmiş ve Türkiye Odalar ve
              Borsalar Birliği görevlendirilmiştir. İleti Yönetim Sistemi A.Ş.,
              Türkiye Odalar ve Borsalar Birliği tarafından bu amaçla hizmet
              vermek üzere kurulmuştur. İleti Yönetim Sistemi, Hizmet
              Sağlayıcılar'ın arama, mesaj ve e-posta gibi farklı tipte ileti
              izinlerini saklayıp yönetebilecekleri, alıcıların verdikleri
              izinleri görüntüleyip kaldırabilecekleri, izinsiz gönderimleri
              şikâyet edebilecekleri, kamunun ise ileti şikâyetlerini ve
              şikâyete konu iznin durumunu görüntüleyebilecekleri, web sitesi,
              kısa mesaj numarası ve çağrı merkezi üzerinden hizmet verecek, tüm
              izinleri zaman damgasıyla kayıt altına alıp güvenli biçimde
              saklayacak ulusal veri tabanı sistemidir.
            </p>

            <p>
              İleti Yönetim Sistemi ile Hizmet Sağlayıcılar'ın, alıcılara
              göndereceği ticari elektronik iletiler öncesinde alması gereken
              izinler güvenli bir ortamda saklanacak, alıcılar onay ve ret
              bildirimlerini yapabilecek, bunun yanında şikâyet hakkını
              kullanabilecektir. Kamu denetleme görevini hızlı ve eksiksiz bir
              şekilde yerine getirebilecektir.
            </p> -->
          </div>

          <!-- <div class="timecont nedir">
            <div class="timeline">
              <div class="title">
                <h2>Takvim</h2>
              </div>

              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part noline"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">Ocak 2020</div>
                  <div class="box">
                    İleti Yönetim Sistemi'nin açılması, Hizmet Sağlayıcı ve
                    vatandaşların bilgilendirilmesi
                    <div class="image">
                      <img
                        src="@/assets/img/hero/zc-01.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">Ocak 2020</div>
                  <div class="box">
                    Hizmet Sağlayıcılar için İleti Yönetim Sistemi
                    başvurularının açılması
                    <div class="image">
                      <img
                        src="@/assets/img/hero/zc-02.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">Temmuz 2020</div>
                  &nbsp;
                  <div class="box">
                    Hizmet Sağlayıcılar için İleti Yönetim Sistemi platformunun
                    açılması
                    <div class="image">
                      <img
                        src="@/assets/img/hero/zc-03.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">1 Ocak 2021</div>
                  &nbsp;
                  <div class="box">
                    Alıcılar (vatandaşlar) ve kamu için İleti Yönetim
                    Sistemi'nin açılması
                    <div class="image">
                      <img
                        src="@/assets/img/hero/zc-04.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">15 Şubat 2021</div>
                  <div class="box">İletişim adresi sayısı 150.000 adedin üzerinde olan hizmet sağlayıcılar için geçmiş tarihli izinlerin onaylı olarak kabul edilmesi
                <tool-tip >İletişim adresi sayısı 150.000 adet ve <br> altında olan hizmet sağlayıcılar için bu tarih <br> 15 Temmuz 2021'dir.
      </tool-tip>
                    <div class="image"><img
                        src="@/assets/img/hero/zc-05.svg"
                        alt=""
                      ></div>
                </div>
                </div>
              </div>









              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">16 Şubat 2021</div>
                  <div class="box">İletişim adresi sayısı 150.000 adedin üzerinde olan hizmet sağlayıcılar için ileti gönderiminde aracı hizmet sağlayıcı sorgusunun başlaması
                <tool-tip >AHS tarafından İYS’de onaylı olmayan telefon numaralarına <br> ticari elektronik ileti gönderiminin durdurulması
      </tool-tip>
                    <div class="image"><img
                        src="@/assets/img/hero/zc-06.svg"
                        alt=""
                      ></div>
                </div>
                </div>
              </div>
              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">1 Haziran 2021</div>
                  <div class="box">İletişim adresi sayısı 150.000 adedin altında olan hizmet sağlayıcılar için geçmiş tarihli izinlerin vatandaşlar tarafından kontrolünün başlaması

                    <div class="image"><img
                        src="@/assets/img/hero/zc-09.svg"
                        alt=""
                      ></div>
                </div>
                </div>
              </div>


              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part"></div>
                    </div>
                  </div>
                  <div class="date">15 Temmuz 2021</div>
                  <div class="box">İletişim adresi sayısı 150.000 adedin altında olan hizmet sağlayıcılar için geçmiş tarihli izinlerin onaylı olarak kabul edilmesi
                  <div class="image"><img
                        src="@/assets/img/hero/zc-08.svg"
                        alt=""
                      ></div>
                </div>
                </div>
              </div>


              <div class="capsule">
                <div class="boxp">
                  <div class="hook">
                    <div class="vert"></div>
                    <div class="circ"></div>
                    <div class="horz">
                      <div class="part"></div>
                      <div class="part noline"></div>
                    </div>
                  </div>
                  <div class="date">16 Temmuz 2021 </div>
                  <div class="box">İletişim adresi sayısı 150.000 adedin altında olan hizmet sağlayıcılar için ileti gönderiminde aracı hizmet sağlayıcı sorgusunun başlaması
                <tool-tip >AHS tarafından İYS’de onaylı olmayan telefon numaralarına <br> ticari elektronik ileti gönderiminin durdurulması
      </tool-tip>
                    <div class="image"><img
                        src="@/assets/img/hero/zc-06.svg"
                        alt=""
                      ></div>
                </div>
                </div>
              </div>







            </div>
          </div> -->
        </div>
      </div>

      <!-- <div class="nextpage">
        <router-link to="/iys/nasil-calisir"
          >İleti Yönetim Sistemi Nasıl Çalışır?</router-link
        >
      </div> -->
    </div>

    <div class="formcontainer staticpage mt2">
      <div class="formbox">
        <h2>İleti Yönetim Sistemi Nasıl Çalışır?</h2>

        <div class="staticbox zebra">
          <img
            src="@/assets/img/icons/nedir-01.svg"
            alt
            class="half"
          />

          <div class="half">
            <!-- <div class="number">#1</div> -->
            <h3>Ticari elektronik ileti izinleri için güvenli veri tabanı</h3>
            <p>Tüm ticari elektronik ileti izinleri İYS de saklanır.
              <br />Ticari elektronik ileti gönderimi yapan tüm <b>
                <tool-tip-hs></tool-tip-hs>lar
              </b>, pazarlama, promosyon, kampanya, indirim, hediye reklam vb. içerikli ticari elektronik iletilerini müşterilerine arama, mesaj ve e-posta olarak iletebilmek için aldıkları ticari elektronik ileti izinlerini İYS’ye yükler.
              <br />Alıcılar yani ise bu izinler için ret haklarını diledikleri zaman kullanırlar.
            </p>
          </div>
        </div>

        <div class="staticbox zebra">
          <img
            src="@/assets/img/icons/nedir-02.svg"
            alt
            class="half"
          />
          <div class="half">
            <!-- <div class="number">#2</div> -->
            <h3>Tek noktadan yönetim</h3>
            <p>Alıcılar, ne zaman, nereden, hangi kanal üzerinden hangi hizmet sağlayıcının veya markasının ticari elektronik ileti göndermesine izin verdiğini görüntüleyebilir, gerektiğinde hizmet sağlayıcılara her bir kanal (<i>arama</i>, <i>mesaj</i> ve <i>e-posta</i>) için verdikleri izinleri İYS üzerinden düzenleyebilir. Tüm bu işlemler iys.org.tr üzerinden, tek bir platformda yapılır.
            </p>
            <br />
          </div>
        </div>

        <div class="staticbox zebra">
          <img
            src="@/assets/img/icons/nedir-03.svg"
            alt
            class="half"
          />
          <div class="half">
            <!-- <div class="number">#3</div> -->
            <h3>
              Ticari elektronik iletiler
              <br />kontrol altında
            </h3>
            <p>Hizmet sağlayıcılar herhangi bir kanaldan (<i>arama</i>, <i>mesaj</i> ve <i>e-posta</i>) ticari ileti göndermek istediklerinde, vatandaşların verdikleri izinler çeşitli yöntemlerle İYS üzerinden kontrol edilerek sadece izin vermiş vatandaşlara gönderim yapılabilir.
              <br />Ret hakkının kullanıldığı durumlarda İYS filtresi sayesinde bu iletiler ret hakkını kullanmış alıcılara gönderilmez.
            </p>
          </div>
        </div>

        <!-- <div class="staticbox zebra">
          <img
            src="@/assets/img/icons/nedir-04.svg"
            alt
            class="half"
          />
          <div class="half">
            <h3>
              Şikâyet yönetimi
              <br />daha hızlı daha kolay
            </h3>
            <p>İYS ile birlikte, vatandaşların ticari elektronik ileti onaylarına ilişkin şikayetlerin hızlı biçimde sonuca ulaştırılması mümkün olacaktır. T.C. Ticaret Bakanlığı, şikayetlerin incelenmesinde İYS üzerinde sorgulama yapabilecek ve bulunan güncel kayıtlara anında ulaşabilecektir.</p>
          </div>
        </div> -->

        <!-- <div class="staticbox zebra">
          <img src="@/assets/img/icons/nedir-05.svg" alt class="half" />
          <div class="half">
            <div class="number">#5</div>
            <h3>İletişim bilgileriniz güvende</h3>
            <p>
              İleti Yönetim Sistemi üzerindeki tüm izinler ve iletişim adresleri, ulusal veri tabanlarında saklanacak, böylelikle veri güvenliği sağlanacaktır.
            </p>
          </div>
        </div> -->
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "IysNedir",
  components: {
    Social
  },
  methods: {
    ...mapMutations("app", ["SET_MODAL"])
  }
};
</script>

<style scoped>
.staticbox:nth-child(odd) {
  flex-direction: row-reverse;
  text-align: right;
}

.staticbox.zebra:nth-child(odd) p {
  text-align: right;
}

.staticbox.zebra:nth-child(odd) h3 {
  text-align: right;
}
.staticbox.zebra:nth-child(even) p {
  text-align: left;
}
.staticbox.zebra:nth-child(even) h3 {
  text-align: left;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
